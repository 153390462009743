import { ginger } from "../styles/fonts";
import styles from "./Footer.module.scss";
import Link from "next/link";

const Footer = () => {
    return (
        <div className={styles.footer}>
            <div className={styles.footerContainer} style={ginger.style}>
                <div className={styles.footerLeft}>
                    <Link href="/">
                        <div className={styles.logo}>
                            <img src={"/assets/logo.svg"} alt="next" />
                        </div>
                    </Link>
                    <p style={ginger.style}>Quality health with convenience</p>
                    <div className={styles.socialmediaContainer}>
                        <a
                            href="https://instagram.com/pillupindia?igshid=YmMyMTA2M2Y="
                            target={"_blank"}
                            rel="noopener noreferrer"
                        >
                            <img
                                src={"/assets/SocialMedia/instagram.svg"}
                                fill={"#fffff"}
                                alt=""
                            />
                        </a>
                        <a
                            href="https://twitter.com/PillUpIndia"
                            target={"_blank"}
                            rel="noopener noreferrer"
                        >
                            <img src={"/assets/SocialMedia/twitter.svg"} alt="" />
                        </a>
                        <a
                            href="https://www.linkedin.com/company/pillupindia/"
                            target={"_blank"}
                            rel="noopener noreferrer"
                        >
                            <img src={"/assets/SocialMedia/linkedin.svg"} alt="" />
                        </a>
                    </div>
                    <div className={styles.downloadApp}>
                        <a href="https://play.google.com/store/apps/details?id=com.pillup">
                            <img src={"/assets/Footer/androidDownload.svg"}></img>
                        </a>
                        <Link href="https://apps.apple.com/in/app/pillup/id6447748859">
                            <img src={"/assets/Footer/iosDownload.svg"}></img>
                        </Link>
                    </div>
                </div>
                <div className={styles.footerRight}>
                    <div className={styles.footerlink}>
                        <div className={styles.link}>
                            <h3>PillUp</h3>

                            <Link
                                href="/HowItWorks"
                                style={{ textDecoration: "none", color: "white" }}
                            >
                                <li>How it works</li>
                            </Link>
                            <Link
                                href="/WhyUs"
                                style={{ textDecoration: "none", color: "white" }}
                            >
                                <li>Why Us</li>
                            </Link>
                            <Link
                                href="/faq"
                                style={{ textDecoration: "none", color: "white" }}
                            >
                                <li>FAQs</li>
                            </Link>
                            <Link
                                href="/blogs"
                                style={{ textDecoration: "none", color: "white" }}
                            >
                                <li>Blogs</li>
                            </Link>
                        </div>
                        <div className={styles.link}>
                            <h3>Legal</h3>
                            <Link
                                href="/terms-and-conditions"
                                style={{ textDecoration: "none", color: "white" }}
                            >
                                <li>Terms & Conditions</li>
                            </Link>
                            <Link
                                href="/privacy-policy"
                                style={{ textDecoration: "none", color: "white" }}
                            >
                                <li>Privacy Policy</li>
                            </Link>
                            <Link
                                href="/refund"
                                style={{ textDecoration: "none", color: "white" }}
                            >
                                <li>Refund & Cancellation</li>
                            </Link>
                            <Link
                                href="/shipping-policy"
                                style={{ textDecoration: "none", color: "white" }}
                            >
                                <li>Shipping Policy</li>
                            </Link>
                        </div>
                        <div className={styles.link}>
                            <h3>Stay Connected</h3>
                            <Link
                                href="/BrandStory"
                                style={{ textDecoration: "none", color: "white" }}
                            >
                                <li>Brand Story</li>
                            </Link>
                            <Link
                                href="https://www.tatvacare.in/about/"
                                style={{ textDecoration: "none", color: "white" }}
                            >
                                <li>About Us</li>
                            </Link>
                            <Link
                                href="/contactus"
                                style={{ textDecoration: "none", color: "white" }}
                            >
                                <li>Contact Us</li>
                            </Link>
                            <a
                                href="mailto:hello@pillup.com"
                                style={{ textDecoration: "none", color: "white" }}
                            >
                                <li>hello@pillup.com</li>
                            </a>
                            <a
                                href="tel:+91 9818360666"
                                style={{ textDecoration: "none", color: "white" }}
                            >
                                <li>9818360666</li>
                            </a>
                        </div>
                    </div>
                    {/* <div className={styles.downloadApp}>
            <a href="https://play.google.com/store/apps/details?id=com.pillup">
              <img src={"/assets/Footer/androidDownload.svg"}></img>
            </a>
            <Link href="/">
              <img src={"/assets/Footer/iosDownload.svg"}></img>
            </Link>
          </div> */}
                </div>
            </div>
            <div className={styles.copyright} style={ginger.style}>
                Copyright © 2024 TatvaCare | All Rights Reserved
            </div>
        </div>
    );
};

export default Footer;
